<template>
  
    <v-dialog v-model="dialog" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="isNew"
          rounded
          v-on="on"
          v-bind="attrs"
          color="green"
          depressed
          class="white--text"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add Tax Code") }}
        </v-btn>
        <v-icon v-if="!isNew" v-on="on" v-bind="attrs" color="blue" @click="fetchData">mdi-pen</v-icon>
       
       
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ isNew ? $t("Add Tax Code") : $t('edit') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-text-field
              :dark="$store.state.isDarkMode"
                outlined
                dense
                :label="$t('Tax Code')"
                v-model="structure.code"
              ></v-text-field>
            </v-col>
            <v-col md="6">
                <v-text-field
                :dark="$store.state.isDarkMode"
                outlined
                dense
                :label="$t('Percent')"
                v-model="structure.percent"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-autocomplete
              :dark="$store.state.isDarkMode"
                outlined
                dense
                :items="type"
                item-text="name"
                item-value="id"
                :label="$t('type')"
                v-model="structure.type_id"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Active')"  v-model="structure.status" checked :true-value="1" :false-value="0"
                  class="mx-4 mb-4"
                  hide-details
             ></v-checkbox>
            
            </v-col>
            <v-col md="12">
                <v-text-field
                :dark="$store.state.isDarkMode"
                outlined
                dense
                :label="$t('notes')"
                v-model="structure.notes"
              ></v-text-field>
            </v-col>
           
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            depressed
            class="white--text"
            @click="submit"
            :loading="btnLoading"
            >{{ $t("storage") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      dialog: false,
    
      type:[],
      
      structure: {
        code: null,
        percent: null,  
        type_id: null,
        status: null,
        notes: null,
      
      },
    };
  },
  props: {
    
    isNew: {
            type: Boolean,
            default: true,
        },
      item: {
        type: Object,
        default: () => {
                return {};
            },
      }  
  },
  computed: {
 
  },
  methods: {
    
 

    async submit() {
      try {
       
        this.btnLoading = true;
        if(this.structure.status){
          this.structure.status = 'active';
        } else {
          this.structure.status = 'stop';
        }
        if(this.isNew) {
          await axios.post("/accounting/tax", this.structure);
          this.$Notifications(
            this.$t('add success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        } else{
          await axios.put(`/accounting/tax/${this.item.id}`, this.structure);
          this.$Notifications(
            this.$t('edit success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        }
        
        this.$emit("Submitted");
       this.clearData();
      } catch (err) {
      } finally {
        this.btnLoading = false;
      
      }
    },
    clearData(){
      this.structure.code = null;
      this.structure.percent = null;
      this.structure.type_id = null;
      this.structure.status = 1;
      this.structure.notes = null;
      this.dialog = false;
    },
   async fetchData(){
    let res =  await axios.get(`/accounting/tax/${this.item.id}`);
    let data = res.data.data;
    this.type = data.type;
      this.structure.code = data.code;
      this.structure.percent = data.percent;
      this.structure.type_id = data.type.id;
      this.structure.status = data.status;
      this.structure.notes = data.notes;
      if(this.structure.status == 'active'){
        this.structure.status = 1;
        
      } else {
        this.structure.status = 0;
      }
    },
  },
  async created() {
    let res =  await axios.get(`/accounting/tax-type`);
    this.type = res.data.data;
    this.structure.status = 1;

  },
};
</script>

<style>
</style>