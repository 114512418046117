<template>
    <div>
      <v-container class="py-10">
        <v-row>
          <v-col
            md="12"
            class="d-flex  pl-5"
          
          >
           <appAddTaxes
              @Submitted="fetchData(1)"
           >

           </appAddTaxes>
           
          </v-col>
          <v-col md="12">
            <app-base-table
              :tableOptions="tableOptions"
              @re-fetch-data="fetchData"
              @re-fetch-paginated-data="fetchData($event)"
              :enableDelete="false"
            >
              <template slot="editFeature" slot-scope="{ row }">

                <deleteItem path="accounting/tax" :id="row.id"  @record-deleted="fetchData(1)" ></deleteItem>
                <appAddTaxes
                :is-new="false"
              @Submitted="fetchData(1)"
              :item="row"
           >

           </appAddTaxes>
              </template>
            </app-base-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  <script>
  import addTaxes from "./addTaxes.vue";
  import deleteItem from "../../../components/core/delete.vue";
  import axios from "axios";
  export default {
    components: {
        appAddTaxes: addTaxes,
      deleteItem,
    },
    data() {
      return {
        tableOptions: {
            tableData: [],
            tableHeaders: ["id", "Tax Code", "Percent","type","status","notes","settings"],
            accessTableData: [["id"], ["code"], ["percent"],["type","name"],["status"],["notes"]],
            loading: false,
            tableMeta: {
            total: 10,
            page: 1,
            },
        },
      };
    },
    props: {
    
    },
    computed: {

    },
    methods: {
  
     async fetchData(page) {
        this.tableOptions.loading = true;
        let params = {
        size: 10,
        page: page,
      };
        const response = await axios.get("/accounting/tax",{params});
        if (response.status == 200) {
          console.log(response.data.data);
            this.tableOptions.tableData = response.data.data,
       
            this.tableOptions.tableMeta.total = response.data.meta.total;
            this.tableOptions.tableMeta.page =  response.data.meta.current_page;
        

        }
        this.tableOptions.loading = false;
      },
    },
    created() {
     
      this.fetchData(1);
    },
  };
  </script>
  
  <style>
  </style>
  